import { setUpInitialVariables } from './variables';
import { incrementSessionDepth, devtools } from '../../lib/utils';
import PrebidEvents from '../../lib/prebid_events';
import Classifier from '../../lib/classifier';
import NativeAds from '../../lib/native_ads';
import Slots from '../../lib/slots';
import SlotNames from '../../lib/slot_names';
import BidManager from '../../lib/bid_manager';
import Logger from '../../lib/logger';
import Lifecycle from '../../lib/lifecycle';
import PluginLoader from '../../lib/plugin_loader';
import Events, { EventTypes } from '../../lib/events';
import { forceSafeframe, safeframeSettings } from '../../lib/safeframe_config';
import store from '../../lib/store';

export function initMixin(ConcertAds) {
  ConcertAds.prototype._init = function(config = {}) {
    this.settings = {
      prebid: {},
      slots: [],
      classifiers: [],
      ...config,
    };

    this.slots = new Slots({ app: this });
    this.slotNames = new SlotNames();
    this.logger = Logger;
    this.lifecycle = new Lifecycle();

    // Resets the store. Particularly useful for tests.
    store.reset();

    // TODO: Move these to third-party adapter
    setUpDfp(this);
    setUpAmazon();
    setUpPrebid(this);
    this.plugins = new PluginLoader({ app: this });

    this.bootDependenciesOfSettings();

    createCommandQueue(this);
    incrementSessionDepth();

    NativeAds.listenForHymnalRenderEvents();

    if (devtools) {
      devtools.emit('init', this);
    }
  };

  /**
   * Instantiate and run setup methods that interact with the app's provided
   * settings. This is available as a public API method so it can be re-run
   * after a remoteConfig is loaded, for example.
   */
  ConcertAds.prototype.bootDependenciesOfSettings = function() {
    // This contains promises that must be satisfied before the
    // ads/content can be inserted. If we get new settings, we
    // need to clear out this queue.
    this.beforeAdsRequested = [];

    this.bidManager = new BidManager({ app: this });
    this.prebidEvents = new PrebidEvents({ app: this });
    setUpClassifier(this);
    setUpInitialVariables(this);
    Events.emit(EventTypes.settingsAvailable, {});
  };
}

/**
 * Establishes the command queue and fires initial commands passed.
 *
 * @param {ConcertAds} app
 */
function createCommandQueue(app) {
  app.commandQueue = app.settings.cmd || [];
  app.commandQueue.push = fn => {
    if (typeof fn === 'function') {
      try {
        fn.call();
      } catch (e) {
        Logger.log('Error processing command: ' + e.message, {
          level: 'warn',
        });
      }
    }
  };

  // Fire any commands sent before instantiation
  app.commandQueue.forEach(fn => app.commandQueue.push(fn));
}

/**
 * Initialize apstag library and set config to handle setting bid targeting.
 */
function setUpAmazon() {
  /* prettier-ignore */
  if (!window.apstag) {
    !function(a9, a) {
      if(a[a9]) return;
      function q(c, r) { a[a9]._Q.push([c, r]); }
      a[a9] = {
        init: function() { q('i', arguments); },
        fetchBids: function() { q('f', arguments); },
        _Q: []
      };
    }('apstag', window);
  }

  apstag.init({
    pubID: '3176',
    adServer: 'googletag',
  });
}

/**
 * Set up the Classifier class, and ensure ads are blocked from requesting
 * until the Classifier is finished.
 *
 * @param {ConcertAds} app
 */
function setUpClassifier(app) {
  app.classifier = new Classifier({ app });
  app.beforeAdsRequested.push(app.classifier.blockUntilComplete());
}

/**
 * Initialize googletag library.
 */
function setUpDfp(app) {
  window.googletag = window.googletag || {};
  window.googletag.cmd = window.googletag.cmd || [];

  window.googletag.cmd.push(function() {
    googletag.pubads().setForceSafeFrame(forceSafeframe({ settings: app.settings }));
    googletag.pubads().setSafeFrameConfig(safeframeSettings({ settings: app.settings }));

    googletag.enableServices();

    googletag.pubads().addEventListener('slotRenderEnded', evt => {
      app.slots.getByGoogleSlot(evt.slot).rendered(evt);
    });
    googletag.pubads().addEventListener('slotOnload', evt => {
      app.slots.getByGoogleSlot(evt.slot).loaded(evt);
    });
    googletag.pubads().addEventListener('impressionViewable', evt => {
      app.slots.getByGoogleSlot(evt.slot).viewed(evt);
    });
  });
}

/**
 * Set up Prebid.js for header bidding
 *
 * @param {ConcertAds} app
 */
function setUpPrebid(app) {
  pbjs.que.push(function() {
    pbjs.setConfig({
      // Send all bids, not just the bids that won
      enableSendAllBids: true,
      // Ensure bids are randomized
      bidderSequence: 'random',
      // Turn on iframe based user sync pixels (see https://github.com/voxmedia/revenue-platforms/issues/273#issuecomment-351798327)
      userSync: {
        iframeEnabled: true,
      },
    });

    app.prebidEvents.initialize();
  });
}
