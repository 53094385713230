import Slot from '../../lib/slot';
import SlotWrapper from '../../lib/slot_wrapper';

export function registerMixin(ConcertAds) {
  ConcertAds.prototype.registerSlot = function(config) {
    let slot = new Slot(this, config);
    slot.wrapper = SlotWrapper.newFromExistingSlot(slot);

    this.slots.push(slot);

    slot.inserted();

    return slot;
  };
}
