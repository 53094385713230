import { getSessionDepth, getDeviceType } from '../lib/utils';

/**
 * @file The filters we use for dynamic slot insertion.
 * Each filter takes in as an argument an element, which will be the neighbor
 * element brought in by querySelectorAll. The rest of the arguments are what
 * is passed in fron the dynamic slot config's options.
 */

const rand = Math.random();

export default {
  /**
   *
   * @param  {Object} el        The neighbor element.
   * @param  {Number} threshold A number between 0-1 of to match the percentage
   *                            of times you want this to succeed.
   * @return {Boolean}          Whether or not the slot should be put there.
   */
  random: function(el, threshold) {
    return rand < threshold;
  },

  /**
   * Determine if the slot can be placed based on the viewport width.
   * @param  {Object} el      The neighbor element.
   * @param  {Object} options Object with two available options, min and max.
   * @return {Boolean}        Whether or not the slot should be put there.
   */
  viewportWidth: function(el, options = {}) {
    var min = options.min || 0;
    var max = options.max || Infinity;
    var width = window.innerWidth;
    return width >= min && width < max;
  },

  /**
   * Determine if the slot can be placed based on the viewport height.
   * @param  {Object} el      The neighbor element.
   * @param  {Object} options Object with two available options, min and max.
   * @return {Boolean}        Whether or not the slot should be put there.
   */
  viewportHeight: function(el, options = {}) {
    var min = options.min || 0;
    var max = options.max || Infinity;
    var height = window.innerHeight;
    return height >= min && height < max;
  },

  /**
   * Can the slot fit within the container above?
   * @param  {Object} el      The neighbor element.
   * @param  {Object} options Object with two available options, min and max.
   * @return {Boolean}        Whether or not the slot should be put there.
   */
  containerWidth: function(el, options = {}) {
    var min = options.min || 0;
    var max = options.max || Infinity;
    var width = el.parentNode.clientWidth;
    return width > min && width < max;
  },

  /**
   * Does the UserAgent contain certain values.
   * @param  {Object} el      The neighbor element.
   * @param  {Object} options An object with available options to test by.
   *                          Currently only "includes", a string.
   * @return {Boolean}        Whether or not the slot should be put there.
   */
  userAgent: function(el, opts = {}) {
    return opts.includes && navigator.userAgent.indexOf(opts.includes) > -1;
  },

  /**
   * Does the referrer include a certain string?
   * @param  {Object} el      The neighbor element.
   * @param  {Object} options An object of options. Includes is the only value
   *                          allowed.
   * @return {Boolean}        Whether or not the slot should be put there.
   */
  referrer: function(el, options = {}) {
    var includes = options.includes || undefined;
    return includes !== undefined && document.referrer.indexOf(includes) > -1;
  },

  /**
   * [pagesThisSession description]
   * @param  {Object} el      The neighbor element.
   * @param  {Object} options An object of options to check against.
   *                          Before/after are the two values allowed.
   * @return {Boolean}        Whether or not the slot should be put there.
   */
  pagesThisSession: function(el, options = {}) {
    var sessionDepth = getSessionDepth();
    var before = options.before || undefined;
    var after = options.after || undefined;
    return after <= sessionDepth || before > sessionDepth;
  },

  /**
   * Make sure that there's enough height in the neighboring paragraphs to place the slot.
   * @param  {Object} el      The neighbor element.
   * @param  {Object} options Object with two available options: above, below
   * @return {Boolean}        Whether or not there is room for the slot.
   */
  paragraphHeight: function(el, options = {}) {
    var safeList = 'p, h1, h2, h3, h4, h5, h6, ul, li, ol';
    var directions = Object.keys(options);

    var allClear = directions.every(dir => {
      var r = document.createRange();

      if (dir === 'above') {
        r.setEndBefore(el);
        var prevGraf = el;

        while (prevGraf.previousElementSibling && prevGraf.previousElementSibling.matches(safeList)) {
          prevGraf = prevGraf.previousElementSibling;
        }

        r.setStartBefore(prevGraf);
      } else if (dir === 'below') {
        r.setStartBefore(el);
        var nextGraf = el;

        while (nextGraf.nextElementSibling && nextGraf.nextElementSibling.matches(safeList)) {
          nextGraf = nextGraf.nextElementSibling;
        }

        r.setEndAfter(nextGraf);
      }

      return r.getBoundingClientRect().height >= options[dir];
    });

    return allClear;
  },

  /**
   * Is there enough space above and below the slot?
   * @param  {Object} el      The neighbor element.
   * @param  {Object} options An object of options to check against.
   *                          Accepts values:
   *                          - before // e.g., before: 700
   *                          - after // e.g., after: 500
   *                          - exceptSlot // e.g., exceptSlot: native_quicklistings
   * @return {Boolean}        Whether or not the slot should be put there.
   */
  spacing: function(el, options = {}) {
    function estimateHeight(range) {
      return range.getBoundingClientRect().height;
    }

    function isNotAd(className) {
      if (options.exceptSlot && className.indexOf(options.exceptSlot) > -1) {
        return true;
      } else {
        return !/\bm-ad\b/.test(className);
      }
    }

    var range = document.createRange();

    if (options.before) {
      var prevAd = el.previousElementSibling;

      while (prevAd && isNotAd(prevAd.className)) {
        prevAd = prevAd.previousElementSibling;
      }
      if (prevAd) {
        range.setStartAfter(prevAd);
      } else {
        range.setStart(el.parentNode, 0);
      }
      range.setEndBefore(el);
      if (estimateHeight(range) < options.before) {
        return false;
      }
    }

    if (options.after) {
      var nextAd = el.nextElementSibling;

      while (nextAd && isNotAd(nextAd.className)) {
        nextAd = nextAd.nextElementSibling;
      }
      if (nextAd) {
        range.setEndBefore(nextAd);
      } else {
        range.setEndAfter(el.parentNode.lastChild);
      }
      range.setStartBefore(el);
      if (estimateHeight(range) < options.after) {
        return false;
      }
    }

    return true;
  },

  /**
   * We hide the slot if the selector passed exists on the page.
   * @param  {Object} el           The neighbor element.
   * @param  {Object} [options={}] An object of options to check against.
   *                               selector: string
   *                               count: number (defaults to 1)
   * @return {Boolean}             Whether or not the slot should be put there.
   */
  hideIfPresent: function(el, options = {}) {
    const selector = options.selector || '';
    const count = parseInt(options.count) || 1;

    return document.querySelectorAll(selector).length < count;
  },

  /**
   * We show the slot if the selector passed exists on the page.
   * @param  {Object} el           The neighbor element.
   * @param  {Object} [options={}] An object of options to check against.
   *                               selector: string
   *                               count: number (defaults to 1)
   * @return {Boolean}             Whether or not the slot should be put there.
   */
  showIfPresent: function(el, options = {}) {
    const selector = options.selector || '';
    const count = parseInt(options.count) || 1;

    return document.querySelectorAll(selector).length >= count;
  },

  /**
   * Be able to avoid any flots within the DOM near where we were gonna put the
   * slot.
   * @param  {Object} el The neighbor element.
   * @return {Boolean}   Whether or not the slot should be put there.
   */
  avoidFloats: function(el) {
    const { top, bottom } = el.getBoundingClientRect();

    // Check the given element's top/bottom coordinates against floated siblings.
    // Specifically, if the top and bottom of the element are above the top of
    // the sibling, or if the top is below the bottom of the sibling, it is
    // marked as having avoided a float and returns true.
    for (let [fTop, fBottom] of getFloatedSiblingPositions(el)) {
      if (!((top < fTop && bottom < fTop) || top > fBottom)) {
        return false;
      }
    }

    return true;
  },

  /**
   *
   * @param  {Node}   node             Current insertion node candidate.
   * @param  {Object} options          Filter arguments. Selector + min OR max required.
   * @param  {string} options.selector Valid CSS selector for element.
   * @param  {number} [options.min]    Minimum allowed height of element.
   * @param  {number} [options.max]    Maximum allowed height of element.
   * @return {Boolean}
   */
  elementHeight(node, { selector, min, max }) {
    const el = document.querySelector(selector);

    // If the element can't be found, and neither a min OR max was provided, fail.
    if (!el || (!min && !max)) return false;

    const height = el.getBoundingClientRect().height;

    // Check for a min, a max, or both.
    if (min && height < parseInt(min)) return false;
    if (max && height > parseInt(max)) return false;

    return true;
  },

  /**
   * Only show the slot if the hostname passed matches the page.
   * @param  {Object} el           The neighbor element.
   * @param  {Object} [options={}] An object of options to check against.
   *                               Accepts one or more hostnames as comma-separated strings
   *                               e.g., vox.com, miami.curbed.com, curbed.com
   * @return {Boolean}             Whether or not the slot should be put there.
   */
  matchDomain: function(el, options = {}) {
    var domains = options.domains.split(',');
    return domains.some(domain => window.location.hostname.indexOf(domain) > -1);
  },

  /**
   * Only show the slot if the device of a given type(s) is being used.
   * @param  {Object} el          The neighbor element.
   * @param  {String} deviceList  A comma-separated list of valid device types:
   *                              mobile, tablet, desktop
   */
  deviceType: function(el, deviceList) {
    const devices = deviceList.split(',').map(d => d.trim());

    return devices.indexOf(getDeviceType()) > -1;
  },
};

let floatedSiblingPositions;
let floatedElementPositions;

/**
 * Track sibling floated elements:
 * WeakMap<DOMNode, Array>
 *
 * Note: WeakMap is not available in PhantomJS, and it breaks related tests. This is
 * a "polyfill" in that it does nothing, which is OK, because it never gets
 * executed in PhantomJS.
 */
function createElementTrackers() {
  floatedSiblingPositions = typeof WeakMap === 'undefined' ? {} : new WeakMap();
  floatedElementPositions = typeof WeakMap === 'undefined' ? {} : new WeakMap();
}

createElementTrackers();

/**
 * Fired by consumer of the above filters (SlotBuilder) when a new slot is inserted.
 */
export function resetFilters() {
  createElementTrackers();
}

/**
 * Get the (cached) top/bottom positions for an element.
 * @param {Node} el An element for which to get the top/bottom positions
 * @return {Array<number>,<number}
 */
function getFloatedElementPositions(el) {
  if (floatedElementPositions.has(el)) {
    return floatedElementPositions.get(el);
  }

  let rect = el.getBoundingClientRect();
  let position = [rect.top, rect.bottom];

  floatedElementPositions.set(el, position);

  return position;
}

/**
 * Get an array of arrays [top, bottom] of locations of floated elements in
 * this element's parent container (e.g. siblings).
 *
 * @param {Node} el The node whose parent we want to check.
 * @return {Array<number, number>[]}
 */
function getFloatedSiblingPositions(el) {
  if (floatedSiblingPositions.has(el.parentNode)) {
    return floatedSiblingPositions.get(el.parentNode);
  }

  let positions = [...el.parentNode.children]
    .filter(e => window.getComputedStyle(e).float !== 'none')
    .map(e => getFloatedElementPositions(e));

  floatedSiblingPositions.set(el.parentNode, positions);

  return positions;
}
