import Metrics from './metrics';

/**
 * Detects whether user is using an ad blocker.
 * @returns {boolean}
 */
function isAdBlockEnabled() {
  var fakeAd = document.createElement('div');
  fakeAd.innerHTML = '&nbsp;';
  fakeAd.className = 'adsbox';

  document.body.appendChild(fakeAd);

  // a height of zero indicates element has been blocked
  const isAdBlockEnabled = fakeAd.offsetHeight === 0;
  fakeAd.parentNode.removeChild(fakeAd);

  return isAdBlockEnabled;
}

/**
 * Fetch the ad blocker status, and report it directly to the datalayer (GTM).
 */
export function reportAdBlocker(app) {
  const isEnabled = isAdBlockEnabled();

  app.adsBlocked = isEnabled;
  Metrics.track('Ads Blocked', isEnabled);
}
