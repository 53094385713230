import { performanceMeasure, performanceMark, distanceFromBottom, closestTo, devtools } from './utils';
import Ad from './ad';
import Bid from './bid';
import Constants from './constants';
import Logger from './logger';
import Metrics from './metrics';
import Observer from './observer';
import Events, { EventTypes } from './events';
import { getRefreshRate, isRefreshDisabled } from './refresh_rate';

let uniqueId = 0;

export default class Slot {
  /**
   * Create new Slot
   * @param {object} data
   */
  constructor(app = {}, data = {}) {
    this.app = app;
    this.data = data;

    // Assign or generate configName, and create unique instance name
    this.configName = generateName.call(this);
    this.name = generateInstanceName.call(this);

    applyDefaults.call(this);

    // the default on all of these is true
    if (this.data.hasOwnProperty('prebidEligible') && !this.data.prebidEligible) {
      this.markPrebidIneligible();
    }

    if (this.data.hasOwnProperty('refreshEligible') && !this.data.refreshEligible) {
      this._state.refreshEligible = false;
    }

    if (this.data.hasOwnProperty('watcherEligible') && !this.data.watcherEligible) {
      this._state.watcherEligible = false;
    }

    // We also want to store the ID. Generally, we have just the prefix and the
    // name of the slot, but in case we want to change that, we can.
    this.id = this.data.id ? this.data.id : 'div-gpt-ad-' + this.name;

    // Create a new instance of Ad, which is responsible for displaying creative
    this.ad = new Ad(this);

    /**
     * Placeholder for the slot object returned by DFP eventually
     * @type {object}
     */
    this.slot = null;
  }

  // Should the slot be displayed now, or added to the lazy loading observer?
  canDisplayImmediately() {
    return this.isEager() && !this.isPrebidEligible();
  }

  /**
   * Determine whether this slot can be displayed on the page
   * @return {boolean}
   */
  canBeDisplayed() {
    var context = this.context || {};
    var browserWidthForSlot = context.browser_width || {};

    // First things first, if we don't have sizes, we can't do a thing with you.
    if (this.sizes === undefined) {
      Logger.log('No sizes added.');
      return false;
    }

    // If the requested context device type is not null— and the device type
    // requested by the ad is not what is— then we will not show the ad.
    if (context.device_type && context.device_type.indexOf(this.app.variables.device_type) < 0) {
      Logger.log('Not available for display: device');
      return false;
    }

    // More complex. If the requested browser width minimum is greater than the
    // current with (smaller than what we want) OR if we have a requested max
    // width (not zero) and our max width is less than the current browser
    // width, we do not show the ad.
    if (
      browserWidthForSlot.min > window.innerWidth ||
      (browserWidthForSlot.max !== 0 && browserWidthForSlot.max < window.innerWidth)
    ) {
      Logger.log('Not available for display: browser width');
      return false;
    }

    // Last, but surely not least— ensure that the ad unit is actually on the
    // page. Does a quick check to see if the element in question exists, and if
    // it does not, we skip it entirely.
    if (this.app.dom && !this.app.dom.querySelector(`#${this.id}`)) {
      Logger.log('Not available for display: element');
      return false;
    }

    if (this.app.settings.isPreview) {
      return false;
    }

    return true;
  }

  /**
   * Destroy this slot's wrapper and DOM element, and remove it from DFP.
   */
  destroy() {
    this.wrapper.destroy();

    if (googletag.pubads) googletag.pubads().clear([this.slot]);

    Logger.log(`Slot ${this.name} was destroyed`);
  }

  /**
   * Method that runs once the slot is inserted into the DOM
   * @return {undefined}
   */
  inserted() {
    if (!this.canBeDisplayed()) {
      return;
    }

    Events.emit(EventTypes.slotInserted, { slotName: this.name });

    // Publish it to DFP
    publishToDfp.call(this);

    // Create a new Bid for this slot
    if (!this.bid) {
      this.bid = new Bid(this);
    }

    if (this.canDisplayImmediately()) {
      this.showAd();
    } else {
      Observer.watchSlot(this);
    }
  }

  /**
   * Callback run when the observer marks the slot as visible.
   * @return {undefined}
   */
  observed() {
    if (this.isBidding()) {
      this.queueUntilAfterBid();
      Logger.log(`${this.name} observed, but queued until header bidding finished`);
    } else {
      this.showAd();
      Logger.log(`${this.name} scrolling into view and displaying`);
      this._state.displayEligible = false;
    }
  }

  /**
   * Callback run when slot is sent away for Prebid
   */
  bidding() {
    this._state.isBidding = true;
    Events.emit(EventTypes.bidSent, { slotName: this.name });
  }

  /**
   * Callback run when slot is finished header bidding
   */
  bidded() {
    this._state.isBidding = false;
    Events.emit(EventTypes.bidComplete, { slotName: this.name });

    // If this slot is eager-loaded, show it now that we have finished prebid
    if (this.isEager() || this.isQueuedUntilAfterBid()) {
      this.showAd();
    }
  }

  /**
   * Callback run when DFP considers the slot to have contained a viewable impression.
   * @return {undefined}
   */
  viewed() {
    Events.emit(EventTypes.adViewed, { slotName: this.name });
    queueRefresh.call(this);
  }

  /**
   * Callback run when DFP slot is loaded
   * @return {undefined}
   */
  loaded() {
    this._state.loadTime = Date.now() - this._state.loadTime;
    Events.emit(EventTypes.adLoaded, { slotName: this.name });

    performanceMark(`${this.name}-end`);
    performanceMeasure(`${this.name}`);

    Logger.log(`Slot ${this.name} loaded in ${this._state.loadTime}ms.`);

    if (!this._state.collapsed && this.renderedSize && !this._state.hasBeenRefreshed) {
      Metrics.track(`${this.getSizeString()}:loaded`, closestTo(this._state.loadTime, Constants.RESPONSE_TIME_BUCKETS));
      Metrics.track(
        `${this.getSizeString()}:loaded-location:`,
        closestTo(distanceFromBottom(this.getElement()), Constants.RENDER_LOCATION_BUCKETS)
      );
    }
  }

  /**
   * Callback run when DFP slot is rendered
   * @param {Event}     Event from DFP
   * @return {undefined}
   */
  rendered(evt) {
    this._state.rendered = true;

    // Update the render time
    this._state.renderTime = Date.now() - this._state.renderTime;
    Events.emit(EventTypes.adRendered, { slotName: this.name });

    Logger.log(`Rendering slot: ${this.name}`);

    this.renderedSize = this.wrapper.getIframeSize();

    // Run callback on Ad object
    this.ad.rendered();

    // Run callback on SlotWrapper object
    this.wrapper.rendered(evt);

    // Record metrics
    if (!this._state.collapsed && this.renderedSize && !this._state.hasBeenRefreshed) {
      Metrics.track(
        `${this.getSizeString()}:rendered`,
        closestTo(this._state.renderTime, Constants.RESPONSE_TIME_BUCKETS)
      );
      Metrics.track(
        `${this.getSizeString()}:rendered-location`,
        closestTo(distanceFromBottom(this.getElement()), Constants.RENDER_LOCATION_BUCKETS)
      );
    }
  }

  /**
   * Callback run when the slot is starting to be collapsed
   * @return {undefined}
   */
  collapsed() {
    this._state.collapsed = true;
    this._state.refreshEligible = false;
    Events.emit(EventTypes.slotCollapsed, { slotName: this.name });
  }

  /**
   * Should slot be eager loaded?
   * @return {Boolean}
   */
  isEager() {
    return !this.isWatcherEligible() && this.isDisplayEligible() && !this.isDisplayed();
  }

  /**
   * Show an ad in this slot
   * @return {undefined}
   */
  showAd() {
    if (!this.isDisplayEligible() || this.isDisplayed()) return;

    Promise.all(this.app.beforeAdsRequested).then(() => {
      // Show the ad.
      this._state.loadTime = Date.now();
      performanceMark(`${this.name}-start`);

      this.ad.show();

      if (devtools) {
        devtools.emit('flush');
      }
    });
  }

  /**
   * Get the holdSize for a slot, falling back to preview if given
   * @return {mixed} Array [100,100] or null
   */
  getHoldSize() {
    if (this.holdSize) {
      return this.holdSize;
    }

    if (this.app.settings.isPreview && this.previewHoldSize) {
      return this.previewHoldSize;
    }

    return null;
  }

  /**
   * Does this slot have the given size [w, h]?
   * @param  {Array}  size
   * @return {Boolean}
   */
  hasSize(size) {
    return this.sizes.some(s => {
      return (
        s
          .slice()
          .sort()
          .join(',') ===
        size
          .slice()
          .sort()
          .join(',')
      );
    });
  }

  /**
   * Get the slot element by its ID. If element is not cached, finds and caches element.
   * @return {HTML element}
   */
  getElement() {
    if (!this.element) {
      this.element = document.getElementById(this.id);
    }
    return this.element;
  }

  /**
   * Refresh an ad slot. Called internally and in tests.
   * @return {undefined}
   */
  refresh() {
    // if a slot is being refreshed, we recreate the slot limiting it
    // only to the size that it was initially rendered at
    // so that the page does not jump
    Logger.log(
      `${this.name} being destroyed so we can rebuild it to only accept ${this.renderedSize[0]} and ${
        this.renderedSize[1]
      }`
    );

    // Preserve the height so there is no mobile jank
    this.wrapper.preserveHeight();

    // FYI, googletag should definitely be loaded by this point. Just not for tests.
    googletag.cmd.push(() => {
      // We then destroy the slot according to google.
      googletag.destroySlots([this.slot]);
    });

    // Reset the slot state
    applyDefaults.call(this, true);
    this.bid = null;

    this._state.hasBeenRefreshed = true;
    Events.emit(EventTypes.slotRefreshed, { slotName: this.name });

    // Kick off the inserted callback again
    this.inserted();
  }

  /**
   * Prevents a slot from rendering until prebid has finished
   */
  queueUntilAfterBid() {
    this._state.queuedUntilAfterBid = true;
  }

  isCollapsed() {
    return this._state.collapsed;
  }

  isDefined() {
    return this._state.defined;
  }

  isDisplayEligible() {
    return this._state.displayEligible;
  }

  isDisplayed() {
    return this._state.displayed;
  }

  isPrebidEligible() {
    return this._state.prebidEligible;
  }

  isBidding() {
    return this._state.isBidding;
  }

  isQueuedUntilAfterBid() {
    return this._state.queuedUntilAfterBid;
  }

  isRefreshEligible() {
    return this._state.refreshEligible && !isRefreshDisabled({ settings: this.app.settings });
  }

  isRefreshQueued() {
    return this._state.refreshQueued;
  }

  isWatcherEligible() {
    return this._state.watcherEligible;
  }

  hasBeenRefreshed() {
    return this._state.hasBeenRefreshed;
  }

  /**
   * Set a slot as defined within DFP. While this is only called internally in this
   * class, it is also used during tests.
   *
   * This also calls a refresh listener for ads that might want to individually
   * disable refreshing.
   *
   * @return {undefined}
   */
  setDefined() {
    this._state.displayEligible = true;
    this._state.defined = true;

    if (!this.listeningForRefreshEligibility) {
      listenForRefreshEligibility.call(this);
    }
  }

  /**
   * Returns the name of the rendered size as `width x height` of the slot
   * ie: 300x250, 300x50, ...
   * @return {String} A string representation of the size.
   */
  getSizeString() {
    return this.renderedSize ? this.renderedSize.join('x') : '-';
  }

  /**
   * Mark a slot as prebid ineligible. Called from constructor and Bid.
   * @return {undefined}
   */
  markPrebidIneligible() {
    this._state.prebidEligible = false;
  }

  /**
   * Mark this slot as displayed. Called from Ad.
   * @return {undefined}
   */
  markAsDisplayed() {
    this._state.displayed = true;
  }

  /**
   * Reset this slot's refresh queued status. Called from Ad.
   * @return {undefined}
   */
  resetRefreshQueued() {
    this._state.refreshQueued = false;
  }

  /**
   * Set targeting on the DFP slot
   * @param {string} key   Targeting key
   * @param {mixed} value  Targeting value
   */
  setTargeting(key, value) {
    this.slot.setTargeting(key, value);
  }

  /**
   * Determine if a given DOM node is eligible to have a sibling ad rendered
   * @param  {Node} node   Element
   * @return {boolean}
   */
  static canHaveSibling(node, config) {
    if (!node) {
      return true;
    }

    // If previous element isn't an ad, it can have a sibling
    if (!/\bm-ad\b/.test(node.className)) {
      return true;
    }

    let slot = node.children[0].__slot__;

    // If the slots share the same name, they shouldn't be placed next
    // to one another regardless of the allowSiblings setting
    if (slot && slot.name === config.name) {
      return false;
    }

    // Check if the slot can have a sibling
    if (slot && slot.data.allowSiblings) {
      return true;
    }

    return false;
  }
}

/**
 * Generate a new slot name
 * @return {string}
 */
function generateName() {
  return this.data.name && this.data.name.length ? this.data.name : generateDynamicName();
}

/**
 * Create the instance name of the slot, incremented with an integer if it's
 * one of many of the same type.
 *
 * Since we key by name (and DFP does as well) we need to ensure that the
 * name is actually unique. We also use the name as-is for the
 * configuration of other things, such as the Prebid data. To ensure we do
 * not duplicate any slots, we will add some logic that will test for the name.
 * @return {string}
 */
function generateInstanceName() {
  if (!this.app.slotNames.exists(this.configName)) {
    this.app.slotNames.increment(this.configName);
    return this.configName;
  }

  let slotName = this.configName;
  let count = this.app.slotNames.getCount(this.configName);
  slotName = `${slotName}_${count}`;

  this.app.slotNames.increment(this.configName);

  return slotName;
}

/**
 * We take anything passed on as an extra option, which could include things
 * like this slot is not prebid eligible, and add it into the data we want
 * to store in the recorded slots.
 * @return {object} Official slot object
 */
function applyDefaults(refresh = false) {
  // Mark keys to exclude from data
  const exclude = ['name'];

  // These defaults live directly on the Slot object
  let slotDefaults = {
    index: 0,
    sticky: false,
    sizes: [],
    customSizes: [],
    context: {},
    outOfPage: false,
  };

  // We only want to set these if we are not refreshing.
  if (!refresh) {
    slotDefaults.element = false;
    slotDefaults.renderedSize = false;
    slotDefaults.listeningForRefresh = false;
  }

  for (var def in slotDefaults) {
    if (slotDefaults.hasOwnProperty(def)) this[def] = slotDefaults[def];
  }

  for (var d in this.data) {
    if (exclude.indexOf(d) > -1) continue;
    if (this.data.hasOwnProperty(d)) this[d] = this.data[d];
  }

  // We add this in after we handle defaults because we want to make sure it
  // always overrides.
  if (refresh) {
    this.sizes = [this.renderedSize];
    this.holdSize = this.renderedSize;
  }

  // Now, we add in the attributes that under no circumstances do we want to
  // be able to override as we register this slot.
  this._state = {
    collapsed: false,
    defined: false,
    displayEligible: false,
    prebidEligible: true,
    refreshEligible: true,
    watcherEligible: true,
    hasBeenRefreshed: false,
    isBidding: false,
    queuedUntilAfterBid: false,
    displayed: false,
    renderTime: false,
    loadTime: false,
    refreshQueued: false,
  };

  autoDisablePrebid.call(this);

  return this;
}

/**
 * Be able to nip prebid disabling in the bud if we marked the slot as
 * disabled.
 */
function autoDisablePrebid() {
  let prebidSettings = this.app.settings.prebid || {};
  let defaultConfig = prebidSettings.defaultConfig || {};

  if (
    (prebidSettings[this.configName] && prebidSettings[this.configName].disabled) ||
    (defaultConfig[this.configName] && defaultConfig[this.configName].disabled)
  ) {
    this._state.prebidEligible = false;
  }
}

/**
 * Publish the slot to DFP
 * @return {undefined}
 */
function publishToDfp() {
  if (this.isDefined()) {
    return false;
  }

  this.setDefined();

  googletag.cmd.push(() => {
    var adSlot;
    var slug = this.slug !== undefined ? this.slug : this.app.settings.slug;
    var sizes = this.sizes;

    if (this.customSizes && this.customSizes.length > 0) {
      var customSize = this.customSizes.filter(custom => {
        return this.index === custom.placement;
      });

      if (customSize.length > 0) {
        sizes = customSize[0].sizes;
        Logger.log(`${this.name} using custom sizing ${customSize[0].sizes}`);
      }
    }

    // Get the slot object, after sending it all the information.
    if (this.outOfPage) {
      adSlot = googletag.defineOutOfPageSlot(slug, this.id).set('slot_name', this.name);
    } else {
      adSlot = googletag.defineSlot(slug, sizes, this.id).set('slot_name', this.name);
    }

    // Add service
    adSlot.addService(googletag.pubads());

    Events.emit(EventTypes.slotPublishedToAdServer, { slot: adSlot, name: this.name });

    // Set the targeting position to the slot name.
    adSlot.setTargeting('position', this.name);

    // Only if we don't have a hold size do we want to collapse the empty div.
    // Note: We cannot do this on slots that we are going to watch— else they
    //       will never show up.
    if (this.getHoldSize() === null && !this.isWatcherEligible()) {
      adSlot.setCollapseEmptyDiv(true, true);
    }

    // Set slot targeting, when resolved.
    (this.data.targeting || []).forEach(keyAndValue => {
      Logger.log(this.name + ' custom targeting: ' + keyAndValue[0] + ' => ' + keyAndValue[1]);
      adSlot.setTargeting(keyAndValue[0], keyAndValue[1]);
    });

    // Store the slot within our slot instance
    this.slot = adSlot;
  });
}

/**
 * A function that listens for an ad's enable/disabled automatic refresh
 * and changes it depending on the passed value
 */
function listenForRefreshEligibility() {
  // Bypass this if running a unit test, and no element exists
  if (!this.getElement()) {
    return;
  }

  this.listeningForRefreshEligibility = true;
  this.getElement().addEventListener('refreshEligibilityToggle', evt => {
    this._state.refreshEligible = evt.detail.hasOwnProperty('refresh') ? !!evt.detail.refresh : false;
    Logger.log(`The refresh eligibility event was fired for ${this.data.name} `);
  });
}

/**
 * Queue up a refresh for this slot
 * @return {undefined}
 */
function queueRefresh() {
  // this logic only applies if we can refresh the slot
  if (!this.isRefreshEligible() || this.isRefreshQueued()) {
    return false;
  }

  this._state.refreshQueued = true;

  setTimeout(() => {
    // We can disable refeshing globally, and if we do we want to do that
    // here. Just to make sure we catch it if that changes between the
    // timout and now, we capture that.
    if (this.app.settings.disableRefreshing) {
      Logger.log(`Refreshing disabled: slot ${this.name} has not been refreshed`);
    } else {
      Logger.log(`Starting refresh process for ${this.name}.`);

      this.refresh();
    }
  }, getRefreshRate({ settings: this.app.settings }));
}

/**
 * Generates a dynamic name for slots that don't have a name specified
 * @return {string}
 */
function generateDynamicName() {
  return `v-dynamic-slot--${++uniqueId}`;
}
