import Filters, { resetFilters } from '../../filters/ad_insertion';
import Constants from '../../lib/constants';
import Events, { EventTypes } from '../../lib/events';
import Slot from '../../lib/slot';
import SlotWrapper from '../../lib/slot_wrapper';
import Logger from '../../lib/logger';
import { clone, extend } from '../../lib/utils';
import { reportAdBlocker } from '../../lib/ad_block_detector';

class Installer {
  constructor(app, dom) {
    this.app = app;
    this.dom = dom || document;
    this.app.dom = this.dom;

    /**
     * Keep track of how many times a slot is inserted, in case there's a maxSlots
     * filter set on the slot.
     * @type {Object}
     */
    this.timesInserted = {};
  }

  insertSlot(dynamicConfig) {
    let config = clone(dynamicConfig);

    Logger.log('Using dynamic rule: ' + config.name + ' - ' + config.selector);

    if (configInvalidatedByViewport(config)) {
      Logger.log(`Skipping dynamic rule: ${config.name} - ${config.selector} because of a viewport filter`);
      return;
    }

    // Since NodeList.forEach is not supported in all browsers yet, we're borrowing
    // Array.prototype's forEach method to iterate over each node in the NodeList
    [...this.dom.querySelectorAll(config.selector)].forEach(neighbor => {
      // create a local, mutable copy of the config for this slot instance
      let slotConfig = clone(config);

      if (slotConfig.maxSlots && this.timesInserted[slotConfig.name] >= slotConfig.maxSlots) {
        Logger.log(`${slotConfig.name} wasn't rendered because maxSlots of ${slotConfig.maxSlots} reached`);
        return false;
      }

      if (!filterSlot(slotConfig, neighbor)) {
        return false;
      }

      var prev = neighbor.previousElementSibling;
      if (!Slot.canHaveSibling(prev, slotConfig)) {
        return false;
      }

      if (containerLimitMaxedOut.call(this, prev)) {
        Logger.log(`${slotConfig.name} wasn't rendered. The container has enough slots.`);
        return false;
      }

      if (slotConfig.sizes === undefined) {
        Logger.log(`${slotConfig.name} wasn't inserted. Sizes were not provided.`);
        return false;
      }

      slotConfig.sizes = reduceSizesByFilters(slotConfig, neighbor);

      if (!slotConfig.sizes.length) {
        Logger.log(`${slotConfig.name} has no more slot sizes after filters; skipping`);
        return false;
      }

      const slot = new Slot(
        this.app,
        extend(
          {
            index: this.timesInserted[slotConfig.name] || 0,
          },
          slotConfig
        )
      );

      this.app.slots.push(slot);

      var wrapper = new SlotWrapper({ slot, config: slotConfig });

      incrementSlotCount.call(this, slotConfig);

      wrapper.insertNextTo(neighbor);

      resetFilters();
    });
  }
}

/**
 * Determine if a slot will pass a filter it has specified (if any)
 * @param  {object} config Slot config
 * @param  {Node} el       Neighbor element
 * @return {Boolean}       True if passes, false if not
 */
function filterSlot(config, el) {
  if (!config.filters) {
    return true;
  }

  var filterName, filterArg, filter;
  var filters = Object.keys(config.filters);
  for (var i = 0; i < filters.length; i++) {
    filterName = filters[i];
    filterArg = config.filters[filterName];
    filter = Filters[filterName];

    if (!filter) {
      continue;
    }

    if (!filter(el, filterArg)) {
      Logger.log(`${config.name} didn't pass filter: ${filterName}`);
      return false;
    }
  }

  return true;
}

/**
 * Increment the number of times a slot is inserted. For DynamicAds usage only
 * to respect the maxSlots param set on the config.
 * @param  {object} config Dynamic config
 * @return {undefined}
 */
function incrementSlotCount(config) {
  this.timesInserted[config.name] = this.timesInserted[config.name] + 1 || 1;
}

/**
 * Will this slot be added to a container that has a limit on how many slots
 * can be added to it?
 * @param  {Node} node   Previous element/neighbor
 * @return {Boolean}     True if the container is maxed out; false if not.
 */
function containerLimitMaxedOut(node) {
  return Object.keys(Constants.CONTAINER_LIMITS).some(selector => {
    if (!this.dom.querySelectorAll(selector).length) {
      return false;
    }

    // If the selector contains the node where we're inserting the ad, check
    // to see if this selector already has the max number of ads
    if (this.dom.querySelector(selector).contains(node)) {
      return this.dom.querySelector(selector).querySelectorAll('.m-ad').length >= Constants.CONTAINER_LIMITS[selector];
    }

    return false;
  });
}

/**
 * Check to see if config is invalidated by the current viewport filter.
 * @param {Object} config
 */
function configInvalidatedByViewport(config) {
  if (config.filters && config.filters.viewportWidth) {
    let filter = config.filters.viewportWidth;
    delete config.filters.viewportWidth;

    return !filterSlot({
      name: config.name,
      filters: {
        viewportWidth: filter,
      },
    });
  }

  return false;
}

/**
 * Reduce the sizes array by any filters specifed on individual sizes.
 *
 * @param {{ sizes: any, name: string }} slotConfig
 * @param {Element} neighbor Neighboring element
 * @returns [number, number][] Sizes
 */
function reduceSizesByFilters({ sizes, name }, neighbor) {
  return sizes.reduce((flattenedSizes, size) => {
    if (!size.size) {
      flattenedSizes.push(size);
      return flattenedSizes;
    }

    if (size.filters && !filterSlot(size, neighbor)) {
      Logger.log(`${name} had a slot size fail filters: ${size.size.join('x')}`);
    } else {
      flattenedSizes.push(size.size);
    }

    return flattenedSizes;
  }, []);
}

export function installMixin(ConcertAds) {
  ConcertAds.prototype.install = function(dom) {
    const installer = new Installer(this, dom);
    Events.emit(EventTypes.installing);
    this.settings.slots.forEach(config => installer.insertSlot(config));

    reportAdBlocker(this);

    this.bidManager.init();
  };

  /**
   * Re-install slots in a given area on the page. Defaults to entire document.
   *
   * @param {DOM Node} dom
   */
  ConcertAds.prototype.reinstall = function(dom) {
    this.uninstall();

    this.bidManager.disable();

    this.install(dom);
  };

  /**
   * Uninstall slots from the page.
   */
  ConcertAds.prototype.uninstall = function() {
    this.slots.all().forEach(slot => slot.destroy());
    this.slots.clear();
  };
}
