export default {
  // param used to enable console logger
  DEBUG_ENABLED: window.location.href.indexOf('debug_concert_ads') >= 0,
  // Container limits for number of slots
  CONTAINER_LIMITS: {
    '.c-entry-content': 10,
  },
  // We are instrumenting 1% of our traffic, unless on local or sandbox
  SHOULD_SEND_METRICS: Math.random() < 0.01 || location.href.indexOf('sbndev') != -1,
  // prettier-ignore
  RESPONSE_TIME_BUCKETS: [
    50, 100, 250, 500, 750, 1000, 1250, 1500, 1750, 2000, 3000, 4000, 5000,
    6000, 7000, 8000, 9000, 10000, 11000
  ],
  RENDER_LOCATION_BUCKETS: [-0.5, -0.25, 0, 0.25, 0.5, 0.75, 1.0, 1.25, 1.5, 1.75],
};
