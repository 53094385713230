import Logger from './logger';
import { performanceMark } from './utils';
import moatHelper from './moat_helper';

const KRUX_SEGMENT_LIMIT = 100;

let firstAdRendered = false;

export default class Ad {
  /**
   * Create a new ad instances
   * @param  {Slot} slot   Slot instance
   * @return {Ad}   self
   */
  constructor(slot) {
    this.slot = slot;

    return this;
  }

  show() {
    // Mark that we have sent this for display.
    this.slot.markAsDisplayed();

    var logMessage = '';

    if (this.slot.hasBeenRefreshed()) {
      // Add a key/value to know this is a refresh.
      this.slot.setTargeting('refresh', [true]);
      logMessage += 'Refreshing';
      this.slot.resetRefreshQueued();
    } else {
      logMessage += 'Displaying';
    }

    googletag.cmd.push(() => {
      // Add Krux targeting, if it's available
      if (typeof window.Krux !== 'undefined') {
        const segments = window.Krux.segments;
        if (segments) {
          googletag.pubads().setTargeting('ksg', segments.slice(0, KRUX_SEGMENT_LIMIT));
        }
        googletag.pubads().setTargeting('kuid', window.Krux.user);
      }

      // Add targeting for new slots, in case a slot is being refreshed
      moatHelper.addTargeting();

      googletag.display(this.slot.id);

      Logger.log(`${logMessage} ad slot: ${this.slot.name}`);
    });
  }

  /**
   * Callback method for when an ad is rendered.
   * Creates and dispatches an event if an ad is the first one to render on page
   * @return {undefined}
   */
  rendered() {
    // Only the first ad will mark and dispatch this event
    if (!firstAdRendered) {
      var eventName = 'first_ad_rendered';
      performanceMark(eventName);
      firstAdRendered = true;

      var event = new Event(eventName, { bubbles: true });
      document.body.dispatchEvent(event);
    }
  }
}
