import { TRANSITION_END } from './effects';
import Logger from './logger';
import stickybits from 'stickybits';

// Class name for our dynamic slots.
const DYNAMIC_AD_CLASS = 'dynamic-js-slot';

/**
 * A SlotWrapper is responsible for most, if not all, DOM manipulations for slot
 * objects. The basic lifecycle of a SlotWrapper is as follows:
 *
 * - SlotWrapper is created in SlotBuilder
 * - Creates wrapperElement and slotElement DOM elements
 * - Inserts elements into DOM using insertNextTo() method
 * - On render, slot calls the rendered() callback on SlotWrapper, which sets
 * the height of the slotElement wrapper based on what happened. If the slot
 * is blank, the wrapper collapses elegantly. If it is filled, it sets classes
 * and transitions to the rendered height. It sets it to height: auto; after
 * the transition is complete, in case the ad is iframe-busting.
 * - On view (impressionViewable), if the slot is refreshEligible, it inherits
 * the height of the slot's first child element. This height persists through a
 * refresh event so the reader doesn't see jank.
 */
export default class SlotWrapper {
  /**
   * Slot Wrapper accepts an object with:
   * @param  {Slot}     slot       Instance of Slot
   * @param  {object}   config     Config
   * @param  {boolean}  existing   Whether we're creating from an existing DOM el
   */
  constructor(opts) {
    const { slot, config, existing } = opts;

    this.slot = slot;

    if (!existing) {
      this.config = config || {};

      this.wrapperElement = createWrapperElement.call(this);
      this.slotElement = createSlotElement.call(this);
      this.slot.element = this.slotElement;
      this.slot.wrapper = this;

      // Append the slot inside the wrapper
      this.wrapperElement.appendChild(this.slotElement);
    }

    if (this.slot.element) {
      this.slot.element.__slot__ = this.slot;
    }
  }

  /**
   * Insert this slotWrapper into the DOM
   * @param  {Node} neighbor   DOM node
   * @return {self}
   */
  insertNextTo(neighbor) {
    // If we want to add in the slot after or inside the selector instead of before, we
    // have that option.
    if (this.config.insertion && this.config.insertion === 'after') {
      // Here we check if we are the lastChild. In english, if you are the
      // last child of your own parent - then you are the end of the line.
      if (neighbor.parentNode.lastChild.isEqualNode(neighbor)) {
        // Since we are the end, append.
        neighbor.parentNode.appendChild(this.element);
      } else {
        // Else, we can just insert before the next sibling.
        neighbor.parentNode.insertBefore(this.element, neighbor.nextSibling);
      }
    } else if (this.config.insertion && this.config.insertion === 'inside') {
      // Insert inside
      neighbor.appendChild(this.element);
    } else {
      // Standard insert— before.
      neighbor.parentNode.insertBefore(this.element, neighbor);
    }

    this.slot.inserted();

    if (typeof this.config.sticky === 'object' ? this.config.sticky.enabled : this.config.sticky)
      this.applyStickyPolyfill();

    return this;
  }
  /**
   * Remove this slot wrapper from the DOM.
   */
  destroy() {
    if (this.stickyPolyfill) this.stickyPolyfill.cleanup();
    this.wrapperElement.remove();
  }

  /**
   * Getter method for the wrapperElement
   * @return {Node}
   */
  get element() {
    return this.wrapperElement;
  }

  /**
   * Callback run when the slot has rendered inside the wrapper
   * @param  {Event} evt  Event sent from DFP
   * @return {undefined}
   */
  rendered(evt) {
    // Dispatch an event to the iframe to let it know we have rendered
    dispatchRenderedEventToIframe.call(this, evt);

    const isEmpty = isEmptyOrBlank.call(this, evt);

    // We don't make any changes that might affect a slot's
    // height after it has been refreshed once to prevent jank
    if (!this.slot.hasBeenRefreshed()) {
      addRenderClassesToSlotElement.call(this, isEmpty);

      // Run the slot collapsed callback
      if (isEmpty) this.slot.collapsed();

      // Resize the slot, whether collapsed or full
      resize.call(this, isEmpty, evt);
    }

    if (evt.isEmpty) {
      Logger.log(`${this.slot.name} returned empty from DFP.`);
    }

    if (isBlank.call(this, evt)) {
      Logger.log(`${this.slot.name} collapsed via DFP script.`);
    }
  }

  /**
   * Get the slot iframe's height.
   * @returns {array} [width<int>, height<int>]
   */
  getIframeSize() {
    const iframe = this.slotElement.querySelector('iframe');

    if (!iframe) return false;

    const { width, height } = iframe;
    return [parseInt(width), parseInt(height)];
  }

  /**
   * Get the name of the event that will be emitted when the slot
   * has been rendered.
   * @return {string}
   */
  getRenderedEventName() {
    return this.slot.name + '_rendered';
  }

  /**
   * Callback run when a slot is being refreshed. Measures the height of the
   * slot creative, and persists it for future refreshes.
   * @return {undefined}
   */
  preserveHeight() {
    // Only preserve a height if this is the first time a slot is refreshed
    if (!this.slot.hasBeenRefreshed()) {
      const height = this.getIframeSize()[1] + 'px';
      Logger.log(`Persisting ${this.slot.name} height as ${height} for all future refreshes.`);
      this.slotElement.style.height = height;
    }
  }

  /**
   * Set the height of the slotElement to auto.
   * This method is only called internally, but it is needed for tests.
   */
  setHeightToAuto() {
    this.slotElement.style.height = 'auto';
  }

  /**
   * Applies the sticky polyfill to the wrapper. Only public for the purpose
   * of tests.
   */
  applyStickyPolyfill() {
    this.stickyPolyfill = stickybits(this.wrapperElement, { stickyBitStickyOffset: this.config.sticky.offset });
  }

  /**
   * Create a new SlotWrapper instance from an existing slot on the page. This is
   * used mainly for legacy parts of Chorus.
   * @param  {Slot} slot        Slot instance
   * @return {SlotWrapper}      SlotWrapper instance
   */
  static newFromExistingSlot(slot) {
    let wrapper = new SlotWrapper({ slot, existing: true });

    wrapper.slotElement = document.getElementById(slot.id);

    if (!wrapper.slotElement) {
      return;
    }

    slot.element = wrapper.slotElement;
    slot.element.__slot__ = slot;
    wrapper.wrapperElement = wrapper.slotElement.parentNode;

    return wrapper;
  }
}

/**
 * Resize the slot.
 * @param  {Boolean} isEmpty Was the slot empty?
 * @param  {object}  evt     Event
 * @return {undefined}
 */
function resize(isEmpty, evt) {
  if (isEmpty) {
    // Set the height and width to 0 so it can be animated with CSS
    this.slotElement.style.width = 0;
    this.slotElement.style.height = 0;

    return;
  }

  addHeightFromSlot.call(this, evt);
}

/**
 * Create a wrapper element and return it
 * @return {Node}  Wrapper div
 */
function createWrapperElement() {
  let wrapper = document.createElement('div');
  let classes = ['m-ad', 'm-ad__dynamic_ad_unit'];
  const classNamePrefix = 'm-ad__';

  if (this.config.className) {
    classes.push(this.config.className);
  }

  if (typeof this.config.sticky === 'object' ? this.config.sticky.enabled : this.config.sticky) {
    wrapper.style.cssText += `
      position: -webkit-sticky;
      position: sticky;
      top: ${this.config.sticky.offset}px;
    `;

    if (this.config.name === 'prelude') {
      wrapper.style.cssText += `
        z-index: ${this.config.sticky.zIndex};
        pointer-events: visible;
      `;

      // TODO: Move to Chorus
      let body = document.querySelector('body');
      let bodyStyle = getComputedStyle(body);
      let bodyBackgroundColor = bodyStyle.backgroundColor;
      let rootAlt = document.querySelector('.l-root');

      rootAlt.style.backgroundColor = bodyBackgroundColor;
      rootAlt.style.pointerEvents = 'visible';
      body.style.pointerEvents = 'none';

      // TODO: Move to Concierge
      let target = document.querySelector(this.config.sticky.observationTarget);
      let opts = {
        rootMargin: this.config.sticky.observationRootMargin,
      };

      let observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.intersectionRatio > 0 || entry.isIntersecting) {
            wrapper.style.cssText += `
              position: -webkit-sticky;
              position: sticky;
            `;
          } else {
            wrapper.style.position = 'static';
          }
        });
      });

      observer.observe(target, opts);
    }

    classes.push('m-ad__sticky');
  }

  if (this.config.name) {
    if (!this.config.className) classes.push(classNamePrefix + this.config.name);

    wrapper.setAttribute('data-concert-ads-name', this.config.name);
  }

  wrapper.className = classes.join(' ');

  return wrapper;
}

/**
 * Create a slot element and return the Node
 * @return {Node}
 */
function createSlotElement() {
  let classes = [];
  let adSlot = document.createElement('div');

  adSlot.id = this.slot.id;
  classes.push(DYNAMIC_AD_CLASS);

  if (this.slot.getHoldSize()) {
    const holdWidthOrViewport = Math.min(this.slot.getHoldSize()[0], document.documentElement.clientWidth);

    classes.push('dfp_ad--held-area');
    adSlot.style.minWidth = holdWidthOrViewport + 'px';
    adSlot.style.height = this.slot.getHoldSize()[1] + 'px';
  }

  adSlot.className = classes.join(' ');

  return adSlot;
}

/**
 * Dispatch an event to the IFrame so it can do things when rendered.
 * @param  {Event} evt
 * @return {undefined}
 */
function dispatchRenderedEventToIframe(evt) {
  var iframe = this.slotElement.querySelector('iframe');
  if (iframe && !evt.isEmpty && iframe.height !== 0) {
    var renderEvent;
    if (typeof Event == 'function') {
      renderEvent = new Event(this.getRenderedEventName(), { bubbles: true });
    } else {
      renderEvent = document.createEvent('Event');
      renderEvent.initEvent(this.getRenderedEventName(), true, false);
    }
    this.slotElement.dispatchEvent(renderEvent);
  }
}

/**
 * Is the slot intentionally blank?
 * @param  {Event}  evt
 * @return {Boolean}
 */
function isBlank(evt) {
  // If the script was blanked using a DFP script
  var scriptBlanked = this.slotElement.querySelector('[style*="display"]');
  if (scriptBlanked && scriptBlanked.style.display === 'none') {
    return true;
  }

  // If the script was blanked using a DFP 1x1 empty pixel
  // and the blank pixel wasn't part of the original slot's requested sizes
  return evt.size && evt.size[0] === 1 && evt.size[1] === 1 && !this.slot.hasSize(evt.size);
}

/**
 * Is the slot either empty or intentionally blank?
 * @param  {Event}  evt
 * @return {Boolean}
 */
function isEmptyOrBlank(evt) {
  return evt.isEmpty || isBlank.call(this, evt);
}

/**
 * Add render classes to the slot element based on whether the slot is empty
 * @param {Event} evt
 */
function addRenderClassesToSlotElement(isEmpty) {
  var add = isEmpty ? 'dfp_ad--is-empty' : 'dfp_ad--is-filled';
  var rm = isEmpty ? 'dfp_ad--is-filled' : 'dfp_ad--is-empty';
  this.slotElement.classList.add('dfp_ad--rendered', add);
  this.slotElement.classList.remove(rm);

  // The above class changes trigger a CSS transition. We want to do some cleanup
  // after the transition completes, or 1s max, if the slot is collapsing.
  if (isEmpty) {
    this.slotElement.addEventListener(TRANSITION_END, completeCollapseTransition.bind(this));
    setTimeout(completeCollapseTransition.bind(this), 1000);
  }
}

/**
 * Complete the collapsing transition
 * @return {undefined}
 */
function completeCollapseTransition() {
  this.wrapperElement.classList.add('m-ad__collapsed');
  this.slotElement.removeEventListener(TRANSITION_END, completeCollapseTransition);
}

/**
 * Add the actual returned height to a slot element after it is rendered. This
 * is because we request multiple sizes for a single slot, and we want to change
 * the height of a slot if one was set. If the slot has been refreshed, we don't
 * want to override the current set height, because that is the source of truth
 * for the given slot height instead of what comes back from DFP.
 * @param {Event} evt
 */
function addHeightFromSlot(evt) {
  if (!evt.size || this.slot.hasBeenRefreshed()) {
    return;
  }

  this.slotElement.style.height = evt.size[1] + 'px';

  // Set height to auto after 1s
  setTimeout(() => {
    this.setHeightToAuto();
  }, 1000);
}
