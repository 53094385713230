import Logger from './logger';

/**
 * The required params for each header bidding partner
 * @type {Object}
 */
const REQUIRED_PREBID_PARAMS = {
  openx: ['unit', 'delDomain'],
  appnexus: ['placementId'],
  ix: ['siteId', 'size'],
  rubicon: ['accountId', 'siteId', 'zoneId', 'position'],
  rubiconLite: ['accountId', 'siteId', 'zoneId', 'sizes', 'position'],
  triplelift: ['inventoryCode'],
  trustx: ['uid'],
  consumable: ['networkId', 'siteId', 'unitId', 'unitName'],
  concert: ['partnerId'],
};

/**
 * A Bid is a representation of a Slot's header bids. There is a 1:1 relationship
 * between a Bid and a Slot. The term "bid" is a bit misleading, because a slot
 * can (and will) have many bidding partner configurations, and each configuration
 * can return multiple bids. However, this Bid class is responsible for:
 * 1. Setting up the bid process for a given slot
 * 2. Sending the targeting, returned from bids, back to the Slot
 *
 * A new Bid will be created each time a slot is displayed, including refreshes.
 *
 * A Bid sends itself to the BidManager class when ready.
 */
export default class Bid {
  constructor(slot) {
    this.slot = slot;
    this.app = slot.app;
    this.config = configForSlot.call(this);

    // If prebid is not enabled, or there is no prebid config for this slot,
    // return before attempting to fetch bids and also mark the slot as not
    // prebidEligible (although it may already be).
    if (!this.isEnabled()) {
      this.slot.markPrebidIneligible();
      return;
    }

    // Mark the slot as actively prebidding. This allows us to defer loading
    // of an ad slot until prebidding is complete (or timed out)
    this.slot.bidding();

    this.app.bidManager.addBid(this);
  }

  get prebidSettings() {
    return this.app.settings.prebid;
  }

  /**
   * Is prebid enabled for this slot?
   * @return {boolean}
   */
  isEnabled() {
    return (
      this.app.settings.prebid.enabled && this.slot.isPrebidEligible() && this.slot.isDisplayEligible() && !!this.config
    );
  }

  /**
   * Add targeting to the DFP slot
   */
  addTargeting() {
    googletag.cmd.push(() => {
      var prebidTargetingForSlot = pbjs.getAdserverTargetingForAdUnitCode(this.slot.name);

      // If this returns null, which is always possible.
      if (!prebidTargetingForSlot) {
        this.slot.bidded();
        return;
      }

      Logger.log(`Setting Prebid targeting for ${this.slot.name}.`, {
        prebidTargeting: prebidTargetingForSlot,
        slotName: this.slot.name,
      });

      // Go into all the information sent back from Prebid and add it
      Object.keys(prebidTargetingForSlot).forEach(key => {
        this.slot.setTargeting(key, prebidTargetingForSlot[key]);
      });

      this.slot.bidded();
    });
  }
}

/**
 * Get a list of bids and bidders for a slot
 * @param  {Bid} bid
 * @return {array}
 */
function configForSlot() {
  var bids = [];
  const prebidName = this.slot.prebidName || this.slot.configName;
  const prebidInfoforSlot = this.prebidSettings[prebidName] || {};

  if (prebidInfoforSlot.disabled) {
    return false;
  }

  // Go through all the bidders we allow and create an object for each one.
  Object.keys(REQUIRED_PREBID_PARAMS).forEach(bidder => {
    const bidderParams = prebidInfoforSlot[bidder] || {};
    const defaultBidderParams = (this.prebidSettings.defaultConfig || {})[bidder] || {};

    // Allow us to disable a single slot or partner by configuration.
    if (bidderParams.disabled || defaultBidderParams.disabled) {
      return;
    }

    const requiredParams = REQUIRED_PREBID_PARAMS[bidder];
    const paramSources = [bidderParams, defaultBidderParams];
    const bidsForBidder = bidderRequiresIndividualSizes(requiredParams)
      ? this.slot.sizes.map(size => buildBidParams(requiredParams, { size }, ...paramSources))
      : [buildBidParams(requiredParams, ...paramSources)];

    bidsForBidder.forEach(params => {
      if (params === false) return;
      bids.push({ bidder, params });
    });
  });

  // If we didn't add any bids in there, then we will return false.
  if (bids.length === 0) {
    bids = false;
  }

  return bids;
}

/**
 * Build up a `params` object for a Prebid bid. Accepts a series of objects which
 * cascade from left to right.
 *
 * @param {object}      requiredParams
 * @param {...object}   cascade of sources to resolve
 */
function buildBidParams(requiredParams, ...sources) {
  let params = requiredParams.reduce((memo, param) => {
    const resolvedValue = sources.find(value => value[param]);
    memo[param] = resolvedValue ? resolvedValue[param] : false;
    return memo;
  }, {});

  // If any required keys are missing, bail
  if (!Object.values(params).every(Boolean)) {
    return false;
  }

  return params;
}

/**
 * Determine whether a bidder requires individual sizes enumerated in bids.
 *
 * @param {object} requiredParams
 */
function bidderRequiresIndividualSizes(requiredParams) {
  return requiredParams.indexOf('size') > -1;
}
