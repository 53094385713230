import Logger from '../lib/logger';
import { fetchJson } from '../lib/fetch_json';

/**
 * @file The classifiers we use to determine contents
 */

export default {
  /**
   * Search inside of a selector to find matching string based on a regex
   *
   * @param  {{ selector: string, matching: RegExp }} options
   * @return {Promise<string>} Resolves with the string of matched words once this classifier is complete.
   */
  selectorContentsContains: function(options = {}) {
    const uniqueValueFilter = (v, i, all) => {
      return all.indexOf(v) === i;
    };

    return new Promise(resolve => {
      const selector = options.selector || '';
      const matching = options.matching || false;

      if (!selector || !matching) {
        Logger.error(
          `Skipping classifier 'selectorContentsContains' because it is missing 'selector' or 'matching' param`,
          { options: options }
        );
        resolve(false);
      } else {
        const selectorText = [...this.dom.querySelectorAll(selector)].reduce((fullString, el) => {
          return fullString + ' ' + (el.innerText || el.textContent);
        }, '');

        const match = selectorText.match(matching);

        if (!!match && match.length) {
          resolve(match.filter(uniqueValueFilter).join(' '));
        } else {
          resolve(false);
        }
      }
    });
  },

  /**
   * Add a constant value to the classifier variables
   *
   * @param  {{ value: string|string[] }} options
   * @return {Promise<string|string[]>}
   */
  constantValue: function(options = {}) {
    return new Promise(resolve => {
      resolve(options.value || false);
    });
  },

  /**
   * Fetch targeting key/values from a Motif's remote endpoint. Automatically
   * appends the current URL as a Base64-encoded hash.
   *
   * @param  {{ endpoint: string }} options
   * @return {Promise<{[key: string]: string|string[]}>} Returns an object with targeting key/values.
   */
  loadMotifTargeting(options = {}, url = null) {
    return new Promise(resolve => {
      if (!options.endpoint) {
        Logger.error(`An endpoint must be passed to the loadMotifTargeting classifier`);

        return resolve(false);
      }

      const canonicalElement = document.querySelector('link[rel="canonical"][href]');
      const canonicalURL = canonicalElement ? canonicalElement.href : null;
      url = url || canonicalURL || window.location.href;

      if (!isValidContentUrl(url)) {
        Logger.error(
          `loadMotifTargeting cannot fetch targeting for URL: ${url} because it is not a valid external URL`
        );
        return resolve(false);
      }

      const encodedUrl = base64SafeEncodeUrl(stripHashFromUrl(url));

      fetchJson(`${removeTrailingSlash(options.endpoint)}/${encodedUrl}.json`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => {
          Logger.error(`loadMotifTargeting failed to fetch targeting from ${options.endpoint}`, {
            error: e.message,
          });
          resolve(false);
        });
    });
  },
};

/**
 * Strip a trailing slash from a URL.
 *
 * @param {string} url
 */
function removeTrailingSlash(url) {
  return url.replace(/\/+$/, '');
}

/**
 * Strip the hash param from a URL.
 *
 * @param {string} url
 */
function stripHashFromUrl(url) {
  return url.split('#')[0];
}

/**
 * Return a URL-safe version of a Base64-encoded string.
 * Parsed at Motif's endpoint.
 *
 * @see https://ruby-doc.org/stdlib-2.1.3/libdoc/base64/rdoc/Base64.html#method-i-urlsafe_encode64
 *
 * @param {string} url
 */
export function base64SafeEncodeUrl(url) {
  return btoa(url)
    .replace(/\//g, '_')
    .replace(/\+/g, '-');
}

/**
 * Ensure URL is a valid http(s) URL
 * @param {string} url
 */
function isValidContentUrl(url) {
  return /^https?/.test(url) && !/^https?:\/\/(127\.0\.0\.1|0\.0\.0\.0|localhost)/.test(url);
}
