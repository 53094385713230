import Logger from './logger';

/**
 * Store the observer in a variable for use in the singleton
 * @type {IntersectionObserver}
 */
let _observer;

export default class Observer {
  /**
   * Watch a single slot
   * @param  {Slot} slot
   * @return {undefined}
   */
  static watchSlot(slot) {
    var target = document.getElementById(slot.id);

    // This should ONLY fail during tests
    if (!target) {
      Logger.log(`Could not watch ${slot.name} because it is not in the DOM`);
      return;
    }

    boundsObserver().observe(target);
    Logger.log(`Starting to watch ${slot.name}`);
  }
}

/**
 * Gets the ad observer object since this is a static method
 * @return {Observer}
 */
function boundsObserver() {
  if (!_observer) {
    _observer = new IntersectionObserver(
      (entries, observer) => {
        Logger.log(`Observer is being called`, { entries: entries });

        entries.forEach(function(entry) {
          // IntersectionObserver returns all the entries for every observation
          // But we only want to show ones that are intersecting
          // I AM SORRY:`isIntersecting` is something safari/webkit supports :(((
          if (entry.intersectionRatio > 0.0 || entry.isIntersecting) {
            // All we get from intersectionObserver is the id, but we will use that
            // to get the right slotInfo.
            var slot = entry.target.__slot__;

            // Run the callback on the slot so an ad can be shown.
            // Mark that this slot is not eligible for display any more, as we need
            // to wait for the timeout.
            slot.observed();

            // Stop watching the slot.
            observer.unobserve(entry.target);
          }
        });
      },
      {
        /**
         * This represents the options that we will pass along to IntersectionObserver
         * when we set it up.
         *    rootMargin: The margin that the IntersectionObserver will consider within
         *                the viewport. In this case, it is 100px above the visibile
         *                viewport, and 200px below the visible viewport will be
         *                considered the full viewport in regards to the observer.
         */
        rootMargin: '100px 0px 200px 0px',
      }
    );
  }

  return _observer;
}
