export function fetchJson(url) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.onerror = function() {
      reject(new TypeError('Network request failed'));
    };

    xhr.ontimeout = function() {
      reject(new TypeError('Network request failed'));
    };

    xhr.onabort = function() {
      reject(new DOMException('Aborted', 'AbortError'));
    };

    xhr.onload = function() {
      if (xhr.status !== 200 && xhr.status !== 304) {
        return reject(new Error(`${xhr.status} (${xhr.statusText})`));
      }

      const body = 'response' in xhr ? xhr.response : xhr.responseText;
      try {
        resolve({ data: JSON.parse(body), xhr });
      } catch (e) {
        reject(e.message);
      }
    };

    xhr.open('GET', url, true);
    xhr.setRequestHeader('access-control-expose-headers', 'geo-region');
    xhr.send();
  });
}
