// adData takes all arguments and inserts them into the data-attr
function adDataSelector() {
  return [].map
    .call(arguments, function(id) {
      return `[data-native-ad-id="${id}"]`;
    })
    .join(', ');
}

// helper function that walks through parent elements until a selector is met
function findParent(node, selector) {
  var el = false;
  var parent = node.parentNode;
  while (!el) {
    if (parent && parent.matches(selector)) {
      el = parent;
    } else {
      parent = parent.parentNode;
    }
  }
  return el;
}

// Pass in an ad and a selector if you want to override
// the default selector of `.native-ad-sponsorship`
function makeSponsorClickable(ad, selector) {
  selector = selector || '.native-ad-sponsorship,.native-ad-byline';
  var el = ad._element[0].querySelector(selector);
  if (el) {
    el.style.cursor = 'pointer';
    el.addEventListener('click', function() {
      window.location = ad.clickthroughUrl;
    });
  }
}

export default {
  listenForHymnalRenderEvents() {
    document.addEventListener('hymnal_render', e => {
      if (!e.detail) {
        return false;
      }
      var ad = e.detail.ad;

      if (ad.type === 'Native Ad') {
        switch (ad.design_template) {
          case 'Native Ad Latest':
            this.renderLatestNative(ad);
            break;
          case 'Native Ad Content Link':
            this.renderContentLink(ad);
            break;
        }

        if (ad.trackElement) {
          ad.trackElement(ad._element[0]);
        }
      }
    });
  },

  renderContentLink(ad = null) {
    // omg IE i hate you
    if (!('remove' in Element.prototype)) {
      Element.prototype.remove = function() {
        if (this.parentNode) {
          this.parentNode.removeChild(this);
        }
      };
    }

    var adSlot = findParent(ad._element[0], adDataSelector('container'));
    var fields = ad.fields;

    // delete the duplicate ad inside it causes problems
    var dupe = adSlot.querySelector(adDataSelector('container'));
    if (dupe) dupe.remove();

    // find and remove the svg logo
    var svg = adSlot.querySelector('svg');
    if (svg) svg.remove();

    [...adSlot.querySelectorAll('a')].forEach(a => a.setAttribute('href', ad.clickthroughUrl));

    function applyText(selector, data, removeIfBlank) {
      var el = adSlot.querySelector(selector);
      if (el) el.innerText = data;
      if (!!removeIfBlank && data.trim().length == 0) el.remove();
    }

    // let's select all the possible elements
    var byline = (fields.preamble || 'Advertiser Content From') + ' ' + fields.byline;
    applyText(adDataSelector('byline'), byline);
    applyText(adDataSelector('description'), fields.description);
    applyText(adDataSelector('eyebrow'), fields.eyebrow || '', true);
    applyText(adDataSelector('headline'), fields.title);

    var els = {
      logo: adSlot.querySelector(adDataSelector('logo')),
      preambleBG: adSlot.querySelector(adDataSelector('preamble_bg')),
      thumbnail: adSlot.querySelector(adDataSelector('thumbnail')),
    };

    if (els.preambleBG) {
      els.preambleBG.style.backgroundColor = fields.brand_color;
    }

    if (els.thumbnail) {
      els.thumbnail.classList.remove('lazy-image', 'vox-lazy-load', 'lazy-loaded');
      els.thumbnail.src = fields.thumbnail;
      els.thumbnail.setAttribute('data-original', fields.thumbnail);
      els.thumbnail.alt = fields.description;
      els.thumbnail.style.cssText =
        'background-image:url(' + fields.thumbnail + '); background-size: cover; background-repeat:no-repeat;';
    }

    if (els.logo) {
      els.logo.src = fields.logo;
      els.logo.alt = fields.description;
    }

    makeSponsorClickable(ad);
  },

  // The Latest Native is served in a slot below the list of items
  // this code places the slot in the list at the defined position
  // or at the very end if there's no position/ fewer items than the position calls for
  renderLatestNative(ad) {
    var adSlot = ad._element[0];
    // convert string to integer
    var location = parseInt(ad.fields.position, 10);
    location = isNaN(location) ? 5 : location;

    var container = findParent(adSlot, '.c-rock-list');
    var list = container.querySelector('.c-rock-list__items');
    var items = list.querySelectorAll('li');

    // it offends me to put a div in an ol element so i'm wrapping this in a li
    var wrapper = document.createElement('li');
    wrapper.appendChild(adSlot);

    // if we can put it in the specified location, do so, otherwise put it at the end
    if (items.length > location) {
      list.insertBefore(wrapper, items[location]);
      items[location].remove();
    } else {
      // if that doesn't exist
      list.appendChild(wrapper);
    }
  },
};
