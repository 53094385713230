export default class BidManager {
  /**
   * The BidManager class communicates with our bidding partners, namely two:
   * 1. Prebid.js (which encapsulates many bidding partners)
   * 2. Amazon (which has its own header bidding system, for more money)
   *
   * BidManager accepts instances of the Bid class, and it queues them until they
   * are ready to be fetched. On page load, we wait to define all the slots on the
   * page, queue up their bids, and request them in one batch. As slots refresh
   * further down the page, their bids will be fetched on an individual basis
   * instead of in bulk.
   */
  constructor({ app }) {
    this.app = app;
    this.initialRequestMade = false;
    this.queue = [];
    this.prebidQueue = [];
    this.a9Queue = [];
    this.PREBID_TIMEOUT = this.app.settings.prebid.timeout || 1000;
  }

  /**
   * Add a bid to the queue to be fetched
   * @param {Bid} bid  Bid instance
   */
  addBid(bid) {
    this.queue.push(bid);

    if (this.isReady()) {
      this.fetchBids();
    }
  }

  /**
   * Determine whether Bid should immediately be fetched, or if we're queuing
   * up at the beginning of page load.
   * @return {Boolean}
   */
  isReady() {
    return this.initialRequestMade;
  }

  /**
   * Listen for the initial group of ads to get defined, so we can make one
   * request in bulk.
   */
  init() {
    this.initialRequestMade = true;
    this.fetchBids();
  }

  /**
   * Fetch bids from Amazon and Prebid, and trigger callbacks.
   * This is only called externally during tests.
   * @param  {function} Optional callback method, used during tests.
   * @return {undefined}
   */
  fetchBids(callback = null) {
    this.queue.forEach(bid => this.buildSlotQueues(bid));

    Promise.all([this.fetchA9Bids(), this.fetchPrebidBids()]).then(() => {
      // Set targeting for Amazon Ads. Only wrapped here for testing purposes.
      if (typeof apstag !== 'undefined' && typeof apstag.setDisplayBids === 'function') {
        apstag.setDisplayBids();
      }

      // Allow slot to add its own targeting from Prebid
      this.queue.forEach(bid => bid.addTargeting());

      this.clearQueues();

      // Send a callback for tests
      if (callback) callback();
    });
  }

  /**
   * Disable bid manager while slots are re-added.
   */
  disable() {
    this.initialRequestMade = false;
  }

  /**
   * Build up the Amazon and Prebid bid queues
   * Note: Because we use our name here, we need to set targeting ourself.
   * This is only called externally during tests.
   * @param  {Bid} bid    Bid instance
   * @return {undefined}
   */
  buildSlotQueues(bid) {
    this.prebidQueue.push({
      code: bid.slot.name,
      mediaTypes: {
        banner: {
          sizes: bid.slot.sizes,
        },
      },
      bids: bid.config,
    });

    this.a9Queue.push({
      slotID: bid.slot.id,
      slotName: bid.app.settings.slug,
      sizes: bid.slot.sizes,
    });
  }

  /**
   * Fetch bids from Amazon.
   * This is only called externally during tests.
   * @type {Promise}
   */
  fetchA9Bids() {
    return new Promise(resolve => {
      // For testing only
      if (typeof apstag.fetchBids !== 'function') return resolve();

      apstag.fetchBids(
        {
          slots: this.a9Queue,
          timeout: this.PREBID_TIMEOUT,
        },
        resolve
      );
    });
  }

  /**
   * Fetch bids from Prebid.
   * This is only called externally during tests.
   * @type {Promise}
   */
  fetchPrebidBids() {
    return new Promise(resolve => {
      if (typeof pbjs.requestBids !== 'function') return resolve();

      pbjs.requestBids({
        timeout: this.PREBID_TIMEOUT,
        adUnits: this.prebidQueue,
        bidsBackHandler: resolve,
      });
    });
  }

  /**
   * Clear queues to start anew.
   * This is only called externally during tests.
   */
  clearQueues() {
    this.queue = [];
    this.prebidQueue = [];
    this.a9Queue = [];
  }
}
